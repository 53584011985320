import Modal from '@scripts/util/Modal.js';

class DefaultModal extends Modal {

  constructor(toggle) {

    super(toggle);

    this.onOpenCallback = () => { console.log('default modal opened, do something')};

  }
}

export default DefaultModal;
