import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class ImagesSlider {

  constructor(node) {

    this.swiper = node;

    this.initSwiper();

    node.swiper = this;

  }


  initSwiper() {

    const swiper = new Swiper(this.swiper, {
      modules: [
        Navigation
      ],
      slidesPerView: 1.5,
      spaceBetween: 24,
      navigation: {
        nextEl: this.swiper.querySelector('.swiper-button-next'),
        prevEl: this.swiper.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
        },
        1280: {
          slidesPerView: 3,
        }
      }
    });
  }


}

export default ImagesSlider;
