let registrationModal;

export default {
  init() {

    registrationModal = document.getElementById('registration-modal');

    if(registrationModal) {

      document.querySelectorAll('[data-open-registration-modal]').forEach(toggle => toggle.addEventListener('click', openRegistrationModal));
      registrationModal.addEventListener('click', handleModalClick);

    }
  }
}

const handleModalClick = (e) => {
  if(!e.target.closest('.modal-box') || e.target.closest('[data-action="close-modal"]')){

    closeRegistrationModal();

  }
}


const openRegistrationModal = (e) => {

  e.preventDefault();

  registrationModal.classList.add('is-open');

}

const closeRegistrationModal = () => {

  registrationModal.classList.remove('is-open');

}

