class Modal {

  constructor(toggle) {

    // Modal container and data slot
    this.modalContainer = document.querySelector('#modal');
    this.dataSlot = this.modalContainer.querySelector('[data-slot]');

    // Toggle element that opens the modal
    this.toggle = toggle;

    // Modal details
    this.detail = {
      id: toggle.dataset.contentId,
      state: '',
    }

    // Set empty open and close callbacks
    this.onOpenCallback = () => {};
    this.onCloseCallback = () => {};

    // Initialize the modal
    this.initModal();
  }

  /**
   * Add event listeners for open and close clicks
   */
  initModal = () => {

    this.toggle.addEventListener('click', this.openModal);
    this.modalContainer.addEventListener('click', this.handleModalClick);

  }

  handleModalClick = (e) => {
    if(!e.target.closest('[data-slot]')){

      this.closeModal();

    }
  }

  /**
   * Event handler for opening the modal
   */
  openModal = () => {

    // Replace modal content if the current content is not from this id
    if(this.modalContainer.dataset.contentFrom != this.detail.id) {

      const sourceElement = document.getElementById(this.detail.id);
      const content = sourceElement.innerHTML;

      this.modalContainer.querySelector('[data-slot]').innerHTML = content;
      this.modalContainer.dataset.contentFrom = this.detail.id;

      this.detail.state = 'replaced';

    }

    // Keep the current content
    else {

      this.detail.state = 'keep';

    }

    // Open the modal
    this.modalContainer.classList.add('is-open');

    // Dispatch a modalopen event
    const openEvent = new CustomEvent('modalopen', { detail: this.detail });
    this.modalContainer.dispatchEvent(openEvent);

    // Call open callback
    this.onOpenCallback();

  }

  /**
   * Event handler for closing the modal
   */
  closeModal = () => {

    this.modalContainer.classList.remove('is-open');

    const closeEvent = new Event('modalclose', { detail: this.detail });
    this.modalContainer.dispatchEvent(closeEvent);

    // Call close callback
    this.onCloseCallback();

  }

}

export default Modal;
