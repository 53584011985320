import { Navigation, Pagination } from 'swiper/modules';
import Swiper from 'swiper';

class TestimonialsCardslider {

  constructor(node) {
    this.swiper = node;
    this.parent = node.closest('.swiper-parent');
    this.initSwiper();
  }

  initSwiper() {
    const swiper = new Swiper(this.swiper, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 32,
      autoHeight: false,
      navigation: {
        nextEl: this.parent.querySelector('.swiper-button-next'),
        prevEl: this.parent.querySelector('.swiper-button-prev-mobile'),
      },
      pagination: {
        el: this.parent.querySelector('.swiper-pagination'),
        type: 'fraction',
        clickable: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 1,
          navigation: {
            prevEl: this.parent.querySelector('.swiper-button-prev'),
          },
        },
      },
    });

  }

  init() {
    const testimonials = this.parent.querySelectorAll('.testimonial-card');
    testimonials.forEach(testimonial => {
      const readMore = testimonial.querySelector('[data-readmore-button]');
      const content = testimonial.querySelector('.testimonial-content');
      if (readMore) {
        readMore.onclick = function () {
          readMore.classList.toggle('hidden', true);
          content.classList.toggle('max-h-[128px]', false);
          content.classList.toggle('overflow-hidden', false);
        };
      }
    });
  }
}

export default TestimonialsCardslider;
