import ItemsIndex from "@scripts/components/index/ItemsIndex";

export default {

  init() {
    new ItemsIndex({
      post_type: 'article',
      filters: document.querySelectorAll('[data-filters]'),
      container: document.querySelector('[data-index]'),
      clearButton: document.querySelector('[data-clear-filters]'),
    });

  }
}
