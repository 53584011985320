import Accordion from "@scripts/modules/Accordion";

class Tabs {

  constructor(section) {

    this.section = section;

    // Get tabs elements
    this.clickTabs = section.querySelectorAll('[data-click-tabs]');
    this.selectTabs = section.querySelector('[data-select-tabs]');

    // Listen to clicks and change events
    this.selectTabs.addEventListener('change', this.handleTabSelectChange);
    this.clickTabs.forEach(tab => tab.addEventListener('click', this.handleTabClick));

    // Setup read more behaviour using Accordion
    new Accordion(this.section);

  }

  /**
   * Handle tab select change event
   * @param {Event} e
   */
  handleTabSelectChange = (e) => {

    e.preventDefault();

    // Find corresponding click tab
    const clickTab = this.section.querySelector(`[data-click-tabs][data-tab-id=${e.target.value}]`);

    if(clickTab) {

      // Trigger click on click tab
      const clickEvent = new Event('click');
      clickTab.dispatchEvent(clickEvent);

    }

  }

  /**
   * Handle click on tab
   * @param {Event} e
   */
  handleTabClick = (e) => {

    e.preventDefault();

    const tabId = e.target.closest('[data-tab-id]').dataset.tabId;

    // Toggle active class on tabs
    this.clickTabs.forEach(tab => tab.classList.toggle('active', tab.dataset.tabId == tabId));

    // Select corresponding select option
    this.selectTabs.value = tabId;
    this.selectTabs.querySelectorAll('option').forEach(option => option.toggleAttribute('selected', option.value == tabId));

    // Show the tab content
    this.toggleContentPanels(tabId);

  }

  /**
   * Toggle tab content panels active state
   * @param {string} activeTabId
   */
  toggleContentPanels = (activeTabId) => {

    // Toggle active class on panels by checking match with activeTabId
    this.section.querySelectorAll('[data-tab-content]').forEach(panel => {

      panel.classList.toggle('active', panel.dataset.tabId == activeTabId);

    });

  }


}

export default Tabs;
