import EmbedVideo from '@scripts/components/EmbedVideo.js'

class Video {

  constructor(toggle) {


    this.toggle = toggle;
    this.videoElement = document.getElementById(toggle.dataset.videoId);
    this.player;

    this.init();

  }


  init = () => {

    this.toggle.addEventListener('click', this.playVideo);

  }

  playVideo = async () => {

    if(this.videoElement){

      // Hide poster, teaser and play button
      this.toggle.closest('.video').querySelectorAll('.hide-on-play').forEach(el => el.classList.add('hidden'));

      // Load EmbedVideo
      this.player = await new EmbedVideo(this.videoElement).get();

      this.player.addEventListener('can-play', () => {

        this.player.play();
      });
    }

  }

}

export default Video;
