export default {
  init() {

    document.querySelectorAll('.whr-item').forEach((item) => {

      let href = item.querySelector('a').getAttribute('href');

      item.addEventListener('click', (e) => {

        e.preventDefault();

        window.open(href,'_blank');

      });

    });

  }
}
