import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class SlidingCards {
  constructor(container) {
    this.container = container;
    this.cards = Array.from(this.container.querySelectorAll("[data-sliding-card]"));
    this.setupAnimations();

    window.addEventListener('resize', this.handleResize);
  }

  setupAnimations() {

    ScrollTrigger.getAll().forEach(trigger => trigger.kill());

    const isSmallerThanDesktop = window.innerWidth <= 1024;
    let lastLastCardHeight;

    this.cards.forEach((card, index) => {
      if (index < (this.cards.length - 2)) {
        const cardTimeLine = gsap.timeline({
          scrollTrigger: {
            trigger: card,
            start: isSmallerThanDesktop ? "top 0px" : "top 200px" ,
            scrub: true,
            ease: "Power2.in",
            pin: true,
            pinSpacing: false
          }
        });


        cardTimeLine.fromTo(card,
          { top: isSmallerThanDesktop ? '0px' : '200px'},
          { top: isSmallerThanDesktop ? '0px' : '200px', opacity: 0, scale: 0.9 }
        );
      }

      else if (index === (this.cards.length - 2)) {

        const lastCardHeight = card.offsetHeight;
        const parentRowGap = 24;
        const cardTimeLine = gsap.timeline({
          scrollTrigger: {
            trigger: card,
            start: isSmallerThanDesktop ? '0px' : 'top 200px',
            end: `+=${lastCardHeight - parentRowGap}`,
            scrub: true,
            ease: "Power2.in",
            pin: true,
            pinSpacing: false
          }
        });
        cardTimeLine.fromTo(card,
          { top: isSmallerThanDesktop ? '0px' : '200px' },
          { top: isSmallerThanDesktop ? '0px' : '200px' , opacity: 0, scale: 0.9 }
        );
      }

      else if (index === (this.cards.length - 1)) {
        lastLastCardHeight = card.offsetHeight;
      }

    });

    if (!isSmallerThanDesktop) {
      const content = document.querySelector('[data-sticky-content]');

      if (content) {

        const elementHeight = content.offsetHeight;

        const cardsWrapperHeight = document.querySelector('[data-cards-wrapper]').offsetHeight;
        const calculatedContentHeight  = cardsWrapperHeight - lastLastCardHeight;

        gsap.timeline({
          scrollTrigger: {
            trigger: content,
            start: isSmallerThanDesktop ? '0px' : '-=200',
            end:`+=${calculatedContentHeight}`,
            pin: true,
            pinSpacing: true,
            ease: "Power2.in"
          }
        });
      }


    }

    ScrollTrigger.refresh();
  }

  handleResize = () => {
    this.setupAnimations();
  }
}

export default SlidingCards;
