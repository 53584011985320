import { PlyrLayout, VidstackPlayer } from 'vidstack/global/player';

class EmbedVideo {



  constructor(node) {


    // Create VidstackPlayer with PlyrLayout
    this.player = VidstackPlayer.create({
      target: node,
      layout: new PlyrLayout({}),
    });

  }

  /**
   * Return VidstackPlayer media component
   * @returns VidstackPlayer
   */
  get = () => {
    return this.player
  }
}

export default EmbedVideo;
