class Select {

  constructor(element) {

    this.element = element;
    this.filterEvent = this.setupFilterEvent();
    this.isFiltered = false;

    this.initFilter();

  }

  /**
   * Init filter event listeners
   */
  initFilter = () => {

    // Listen to change event
    this.element.addEventListener('change', this.handleFilterChange);

  }

  /**
   * Set isFiltered property by checking if any filter input is checked
   * @returns boolean
   */
  setIsFiltered = () => {

    this.element.value != '';

  }

  /**
   * Return the name of the filter
   * @returns string
   */
  getName = () => {

    return this.element.dataset.name;

  }

  /**
   * Return the urlParam of the filter
   * @returns string
   */
  getUrlParam = () => {

    return this.element.dataset.urlParam;

  }

  /**
   * Return selected value
   * @returns string
   */
  getValue = () => {

    return this.element.value;

  }

  /**
   * Clear select value
   */
  clear = () => {

    this.element.value = '';

    // Set isFiltered state
    this.setIsFiltered();

  }

  /**
   * Setup a custom event for filter changes
   * @returns CustomEvent
   */
  setupFilterEvent = () => {

    return new CustomEvent('filter', { detail: this });

  }

  /**
   * Filter change callback
   */
  handleFilterChange = () => {

    // Set isFiltered state
    this.setIsFiltered();

    // Fire filter event
    this.element.dispatchEvent(this.filterEvent);

  }
}

export default Select;
