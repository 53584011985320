import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class RelatedResourcesSlider {

  constructor(node) {
    this.swiper = node;
    this.parent = node.closest('.swiper-parent');

    this.initSwiper();
    node.swiper = this;

  }

  initSwiper() {

      this.swiperInstance = new Swiper(this.swiper, {
        modules: [Navigation],
        slidesPerView: 1.1,
        spaceBetween: 24,
        navigation: {
          nextEl: this.parent.closest('.swiper-parent').querySelector('.swiper-button-next'),
          prevEl: this.parent.closest('.swiper-parent').querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          639: {
            spaceBetween: 24,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 32,
            slidesPerView: 'auto',
          }
        }
      });
  }


}

export default RelatedResourcesSlider;
