import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class SpeakerSlider {

  constructor(node) {

    this.swiper = node;

    this.initSwiper();

  }

  initSwiper() {

    const swiper = new Swiper(this.swiper, {
      modules: [
        Navigation
      ],
      slidesPerView: 1,
      spaceBetween: 24,
      navigation: {
        nextEl: this.swiper.querySelector('.swiper-button-next'),
        prevEl: this.swiper.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        500: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2.5,
        },
        1280: {
          slidesPerView: 4,
        }
      }
    });
  }


}

export default SpeakerSlider;
