import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class ResourcesSlider {

  constructor(node) {
    this.swiper = node;
    this.initSwiper();
    node.swiper = this;
    window.addEventListener('resize', this.handleResize.bind(this)); // Handle resize events
  }


  initSwiper() {
    const screenWidth = window.innerWidth;

    if (screenWidth < 640) {
      const swiper = new Swiper(this.swiper, {
        modules: [
          Navigation
        ],
        slidesPerView: 1.1,
        spaceBetween: 24,
        navigation: {
          nextEl: this.swiper.querySelector('.swiper-button-next'),
          prevEl: this.swiper.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
          639: {
            spaceBetween: 24,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 32,
            slidesPerView: 3,
          }
        }
      });
    }

  }


  handleResize() {
    const screenWidth = window.innerWidth;

    // Destroy Swiper instance if the screen is wider than 640px
    if (screenWidth >= 640 && this.swiperInstance) {
      this.swiperInstance.destroy(true, true);
      this.swiperInstance = null;
    }

    // Reinitialize Swiper if the screen is smaller than 640px and the Swiper is not initialized
    if (screenWidth < 640 && !this.swiperInstance) {
      this.initSwiper();
    }
  }
}

export default ResourcesSlider;
