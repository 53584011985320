import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

class LightBox {

  constructor(node) {

    this.swiper = node;

    this.initLightBox();

    node.swiper = this;

  }

  initLightBox() {


    // const lightbox = new PhotoSwipeLightbox({
    //   gallery: '.lightbox-container',
    //   children: '.lightbox-image',
    //   pswpModule: () => import('/photoswipe/photoswipe.esm.js'),
    //   mainClass: 'pswp-with-perma-preloader',
    //   preloaderDelay: 0,
    // });

    // lightbox.init();

}


}

export default LightBox;




