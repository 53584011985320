import domReady from '@roots/sage/client/dom-ready';

// View imports
import common from '@scripts/views/common.js';
import templateArticles from '@scripts/views/template-articles.js';
import templateTeam from './views/template-team';
import templateResources from '@scripts/views/template-resources.js';
import templateContact from './views/template-contact';
import templateCareers from './views/template-careers';

import AnimateFrontPage from './views/frontpage';
import singleEvent from './views/single-event';
/**
 * Application entrypoint
 */
domReady(async () => {



  document.querySelectorAll('.animate-video').forEach(container => new AnimateFrontPage(container));

  // Views
  common.init();

  const body = document.querySelector('body');

  // Articles
  if(body.classList.contains('page-template-template-articles')) {
    templateArticles.init();
  }

  // Team
  if(body.classList.contains('page-template-template-team')) {
    templateTeam.init();
  }

  // Single event
  if(body.classList.contains('event-template-default')) {
    singleEvent.init();
  }

  // Contact
  if(body.classList.contains('page-template-template-contact')) {
    templateContact.init();
  }

  // Careers
  if(body.classList.contains('page-template-template-careers')) {
    templateCareers.init();
  }

  // Resources
  if(body.classList.contains('page-template-template-resources')
    || body.classList.contains('page-template-template-resources-hub')
    || body.classList.contains('page-template-template-newsroom')) {

    templateResources.init();
  }


});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);

window.ajaxUrl = ajax_object.ajax_url;
window.pagination_base = ajax_object.pagination_base;
