import 'lazysizes';
import 'sharer.js';

import navigation from '@scripts/modules/navigation.js'

import Accordion from '@scripts/modules/Accordion.js';
import DefaultModal from '@scripts/modules/DefaultModal.js';
import VideoModal from '@scripts/modules/VideoModal.js';
import LightBox from '@scripts/modules/LightBox.js';
import Video from '@scripts/modules/Video.js'

import LogoSlider from '@scripts/modules/sliders/LogoSlider.js';
import ImagesSlider from '@scripts/modules/sliders/ImagesSlider.js';
import ResourcesSlider from '@scripts/modules/sliders/ResourcesSlider';
import FeaturedResourcesSlider from '@scripts/modules/sliders/FeaturedResourcesSlider';
import RelatedResourcesSlider from '@scripts/modules/sliders/RelatedResourcesSlider';
import EventsSlider from '@scripts/modules/sliders/EventsSlider';
import SlidingCards from '@scripts/modules/SlidingCards';
import SlidingCaseCards from '@scripts/modules/SlidingCaseCards';
import TestimonialsCardsSlider from '@scripts/modules/sliders/TestimonialsCardsSlider.js';
import TeamSlider from '@scripts/modules/sliders/TeamSlider';
import Portals from '@scripts/modules/Portals';
import Tabs from '@scripts/modules/Tabs';

import SpeakerSlider from '@scripts/modules/sliders/SpeakerSlider.js';
import ConferenceSlider from '@scripts/modules/sliders/ConferenceSlider';

import Headroom from "headroom.js";


export default {
  init() {


    navigation.init();

    // Headroom
    new Headroom(document.querySelector('header'), {
      'offset': 40,
      onPin : function() { this.elem.classList.add('bg-white'); },
      onTop : function() { this.elem.classList.remove('bg-white'); },
    }).init();


    const intersectionOptions = {
      threshold: 0.07
    };

    const intersectionObserverForReveal = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('revealed')
        }
      })
      }, intersectionOptions
    )


   /*
    * Trigger the CSS reveal animation for this element
    */
  function revealElementOnScroll() {
    const elements = document.querySelectorAll('.reveal-on-scroll:not(.revealed)');
    elements.forEach(function(element) {
      intersectionObserverForReveal.observe(element)
    });
  }



    /*
    * Trigger the reveal animation on intersection
    */
    revealElementOnScroll()

    document.querySelectorAll('.accordion').forEach(element => new Accordion(element));
    document.querySelectorAll('[data-action=open-modal][data-modal-type=default]').forEach(toggle => new DefaultModal(toggle));
    document.querySelectorAll('[data-action=open-modal][data-modal-type=video]').forEach(toggle => new VideoModal(toggle));
    document.querySelectorAll('.lightbox-container').forEach(node => new LightBox(node));
    document.querySelectorAll('[data-action=play-video]').forEach(toggle => new Video(toggle));
    document.querySelectorAll('.portals-section').forEach(node => new Portals(node));
    document.querySelectorAll('.sliding-cards-section').forEach(node => new SlidingCards(node));


    // document.querySelectorAll('.sliding-case-cards-section').forEach(container => new SlidingCaseCards(container));

    // Sliders
    document.querySelectorAll('.logo-swiper-container').forEach(container => new LogoSlider(container));
    document.querySelectorAll('.images-slider-swiper-container').forEach(node => new ImagesSlider(node));
    document.querySelectorAll('.resources-slider-swiper-container').forEach(node => new ResourcesSlider(node));
    document.querySelectorAll('.featured-resources-slider-swiper-container').forEach(node => new FeaturedResourcesSlider(node));
    document.querySelectorAll('.related-resources-slider-swiper-container').forEach(node => new RelatedResourcesSlider(node));
    document.querySelectorAll('.team-slider-swiper-container').forEach(node => new TeamSlider(node));


    document.querySelectorAll('.events-slider-swiper-container').forEach(node => new EventsSlider(node));
    document.querySelectorAll('.conference-slider-swiper-container').forEach(node => new ConferenceSlider(node));

    document.querySelectorAll('.testimonials-cards-slider').forEach(element => new TestimonialsCardsSlider(element));
    document.querySelectorAll('.speaker-slider-swiper-container').forEach(node => new SpeakerSlider(node));

    // Sections
    document.querySelectorAll('.video-section').forEach(node => new Video(node));
    document.querySelectorAll('.tabs-section').forEach(node => new Tabs(node));


  },
}
