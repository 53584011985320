
class Portals {

  constructor(node) {

    this.node = node;
    this.init();

  }


  init = () => {

    const btn = this.node.querySelector('[data-show-more-btn]');
    const hiddenPortalsCards = this.node.querySelectorAll('.hidden-card');

    if(btn) {
      btn.addEventListener("click", ()=>{
        hiddenPortalsCards.forEach(portal => {
          portal.classList.toggle('hidden', false)
        });

        btn.classList.toggle('hidden', true)

      });
    }
  }
}

export default Portals;
