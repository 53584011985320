import ItemsIndex from "@scripts/components/index/ItemsIndex";

export default {

  init() {

    new ItemsIndex({
      postTypes: document.querySelector('[data-index]').dataset.postTypes,
      filters: document.querySelectorAll('[data-filters]'),
      container: document.querySelector('[data-index]'),
      searchBar: document.querySelector('[data-searchbar]')
    });

  }
}
