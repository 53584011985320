export default {
  init() {
    const branch_select = document.querySelector('#branch-selector');
    const team_member_index = document.querySelector('.team-member-index');
    const team_members = team_member_index.querySelectorAll('.team-member-card');
    const currentBranchSpan = document.querySelector('[data-current-branch-title]');
    const showBranchList = document.querySelector('[data-show-branches-list]');
    const buttons = branch_select.querySelectorAll('button');
    let selectorIsOpen = false;

    showBranchList.addEventListener('click', () => {
      displayBranchList();

      // Start listening for outside clicks only after the selector is shown
      setTimeout(() => {
        selectorIsOpen = true;
      }, 0);
    });

    buttons.forEach(button => {
      button.addEventListener('click', () => displayNewTeamMembers(button));
    });

    displayNewTeamMembers(buttons[0]);

    // Display branch list
    function displayBranchList() {
      branch_select.classList.toggle('hidden', false);
    }

    // Display teammembers based on branch
    function displayNewTeamMembers(button) {

      // Get branch data
      const selected_branch_slug = button.getAttribute('data-branch-slug');
      const selected_branch_name = button.getAttribute('data-branch');

      // Set selected branch title and hide list
      currentBranchSpan.innerHTML = selected_branch_name;
      branch_select.classList.add('hidden');
      selectorIsOpen = false;

      // Toggle active button class
      branch_select.querySelectorAll('[data-branch]').forEach(branchButton => {

        branchButton.classList.toggle('text-gray-300', branchButton === button)

      });

      // Toggle team cards
      team_members.forEach(member => {

        const slug = member.querySelector('[data-branch]').dataset.branchSlug;
        const hideMember = !(slug == selected_branch_slug || 'view-all' == selected_branch_slug);

        member.classList.toggle('hidden', hideMember);

      });
    }

    // Hide selector when clicking outside of the div
    window.addEventListener('click', function(e) {
      if (selectorIsOpen) {
        if (!branch_select.contains(e.target) && e.target !== showBranchList) {
          branch_select.classList.add('hidden');
          selectorIsOpen = false;
        }
      }
    });
  }
}
