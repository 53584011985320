import { DisclosureMenu } from "accessible-menu";
import Breakpoints from '@scripts/util/Breakpoints.js';

let header, menuElement, toggleElement, menuItems;
// Function to initialize the MutationObserver

export default {

  init() {

    // Select DOMElements
    header = document.querySelector('header');
    menuElement = header.querySelector('nav ul.nav');
    toggleElement = header.querySelector('[data-action="toggle-navigation"]');
    menuItems = header.querySelectorAll('.menu-item');

    // Add click listener to navigation toggle
    toggleElement.addEventListener('click', toggleNavigation);

    initMenu(menuElement);

    // Enable/disable hover behaviour per breakpoint
    new Breakpoints({
      'default': () => { menuElement.menu.hoverType = 'off' },
      'nav-bar': () => { menuElement.menu.hoverType = 'on'; },
    });

  },
}

/**
 * Initialize navigation behaviour
 */
const initMenu = (menuElement) => {

  // Create DisclosureMenu
  menuElement.menu = new DisclosureMenu({
    menuElement: menuElement,
    menuItemSelector: '.menu-item',
    menuLinkSelector: '.menu-item > a',
    submenuItemSelector: '.menu-item-has-dropdown',
    submenuToggleSelector: '[data-submenu-toggle]',
    submenuSelector: '[data-submenu]',
    openClass: 'open',
    hoverType: 'on',
  });

  window.addEventListener('resize', () => {

    menuElement.menu.elements.menuItems.forEach(menuItem => {

      if (menuItem.dom.link != null) {

        setDropdownHeight(menuItem);

      }

    });

  });


  menuElement.menu.elements.menuItems.forEach(menuItem => {

    if (menuItem.dom.link != null) {

      setDropdownHeight(menuItem);

      // Listen to expand event
      menuItem.dom.link.addEventListener('accessibleMenuExpand', () => {

        setDropdownHeight(menuItem);
        calculateDropdownPosition(menuItem);

        // Actions for mega menu expansion
        if(menuItem.dom.item.querySelector('.mega-menu')) {

          document.body.classList.add('overlay-active', 'alter-link-transition');
          header.classList.add('white-fill');

        }

      });

      // Listen to collapse event
      menuItem.dom.link.addEventListener('accessibleMenuCollapse', () => {

        // Actions for mega menu expansion
        if(menuItem.dom.item.querySelector('.mega-menu')) {

          document.body.classList.remove('overlay-active', 'alter-link-transition');
          header.classList.remove('white-fill');

        }

      });

    }

  });

}

/**
 * Open and close main navigation
 * @param {boolean} activate
 */
const toggleNavigation = (activate) => {

  // If activate isn't a boolean, use the nav-open class on the header
  activate = (typeof (activate) === 'boolean') ? activate : !header.classList.contains('nav-open');

  // Set open classes
  header.classList.toggle('nav-open', activate);
  document.querySelector('body').classList.toggle('no-scroll', activate);

  // Adjust labels
  const toggleLabel = toggleElement.querySelector('.toggle-label');

  toggleElement.setAttribute('aria-label', activate ? toggleElement.dataset.labelClose : toggleElement.dataset.labelOpen);
  toggleElement.setAttribute('aria-expanded', activate);
  toggleLabel.innerHTML = activate ? toggleLabel.dataset.labelClose : toggleLabel.dataset.labelOpen;

}

/**
 * Set a css variable for the dropdown scrollHeight
 * @param  {object} menuItem
 */
const setDropdownHeight = (menuItem) => {

  if (menuItem.isSubmenuItem) {

    const dropdown = menuItem.elements.childMenu.dom.menu;

    dropdown.style.removeProperty('--element-height');
    dropdown.style.setProperty('--element-height', `${dropdown.scrollHeight}px`);

  }

}

/**
   * Calculate the amount of pixel the dropdown menu needs to be move to the right when to close to the edge of the screen.
   * @param  {object} menuItem
*/
const calculateDropdownPosition = (menuItem) => {

  if (menuItem._submenu) {
    // check if the menu item is not a mega menu

    if (menuItem.dom.item.children != null) {
      if (menuItem.dom.item.children[1].classList.contains('mega-menu')) {
        return
        } else {

        const objDomElement = menuItem._dom;
        const htmlDomElement = objDomElement.item.children[1];
        htmlDomElement.style.left = '50%';
        const elementInfo = htmlDomElement.getBoundingClientRect();
        const gutter = 24

        let newLeft = 0
        const distance = parseInt(window.innerWidth - elementInfo.right);

        //convert distance to postive number and remove the gutter space
        newLeft = (distance - (distance * 2) - gutter)
        if (newLeft > 0) {
          newLeft = '-' + (newLeft + 'px');
        } else {
          newLeft = gutter + 'px'
        }

        if (parseInt(newLeft) > '-' + (elementInfo.width / 2)) {
          if (distance > parseInt(newLeft)) {
            if (distance > elementInfo.width / 2) {
              htmlDomElement.style.left = '50%'
            } else if (distance - gutter > 0) {
              htmlDomElement.style.left = gutter * 3 + 'px'
            } else {
              htmlDomElement.style.left = '50%'
            }
          } else {
            if (distance < gutter && distance > 0) {
              htmlDomElement.style.left = distance + gutter + 'px'
            } else {
              htmlDomElement.style.left = newLeft
            }
          }
        } else {
          htmlDomElement.style.left = '50%'
        }

      }
    }

  }

}
