import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class AnimateFrontPage {
  constructor(container) {
    this.container = container;

    this.heroContentSection = this.container.querySelector('[data-hero-content-section]');
    this.heroStripeMask = this.container.querySelector('[data-has-stripe-mask]');
    this.heroSideStripes = this.container.querySelectorAll('.hero-animation-stripe');
    this.videoWrapper = this.container.querySelector('[data-animated-video-wrapper]');
    this.videoContainer = this.container.querySelector('.video-container');
    this.videoElement = this.container.querySelector('[data-video-element]');
    this.contentWrapper = document.querySelector('#content-footer-wrapper');
    this.contentSection = document.querySelector('#contentSection');
    this.scrollSection = document.querySelector('#scrollSection')

    this.videoSources = {
        mobile: this.videoElement.getAttribute('data-mobile-video-url'),
        tablet: this.videoElement.getAttribute('data-tablet-video-url'),
        desktop: this.videoElement.getAttribute('data-desktop-video-url'),
    };

    this.minWidthForDesktopVideo = 1024
    this.minWidthForDTabletVideo = 768
    this.loopTimer = 5000;
    this.loop = null;

    this.initScrollTriggers();
    window.addEventListener('resize', this.handleResize);
  }

  initScrollTriggers = () => {

    this.applyNewVideoSrc()
    this.clearPreviousStyles();

    // Variables for responsiveness
    const isMobile = window.innerWidth <= 767;
    const isTablet = window.innerWidth <= 1023;
    const isLaptop = window.innerWidth <= 1280;
    const isDesktop = window.innerWidth <= 1440;
    const isLargeScreen = window.innerWidth > 1440 && window.innerWidth < 1919;
    const isXlDesktop = window.innerWidth >= 1920;
    const widthCalc = this.calculateWidth(isXlDesktop);

    // Variables for video Animation
    const restartLoopProgressPercent = 0.02;
    const maxContainerWidth = 1440;
    const MarginDivider = 2;
    const currentPadding = isMobile ? 20 : isTablet ? 32 : isLaptop ? 48 : 64;
    const calculatedMarginLeft = `-${(window.innerWidth - maxContainerWidth) / MarginDivider + currentPadding}px`;


    // HeroStripes
    const stripesTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: this.scrollSection,
        start: '-1px',
        end: '+=100',
        scrub: isMobile ? 0 : 0.8,
      },
    });

    // ContentWrapper
    const contentWrapperTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: this.scrollSection,
        start: '1vh',
        end: '+=100px',
        scrub: isMobile ? 0 : 0.8,
      },
    });

    // Mask
    const maskTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: this.scrollSection,
        start: isMobile ? '-10px' : '-10px',
        end: isMobile ? '+=600' : '+=1200',
        scrub: isMobile ? 0 : 0.8,
      },
    });

    // Content
    const contentTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: this.scrollSection,
        start: '1vh',
        end: '150px',
        scrub: isMobile ? 0 : 0.8,
      },
    });
    let i = 0;

    // Video
    const videoTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: this.contentWrapper,
        pin: isMobile ? false : this.scrollSection,
        pinSpacing: true,
        start: 'top top',
        end: isMobile ? '+=200' : '+=700',
        scrub: isMobile ? 0 : 0.8,

        onEnter: () => {
          this.clearVideoLoop();
          this.LoopVideo(this.loopTimer, isMobile, isTablet);
        },

        onUpdate: (self) => {

          this.scrollSection.style.height = 'fit-content';

          if (self.progress > restartLoopProgressPercent) {
            this.clearVideoLoop();
          }

          if (self.progress < restartLoopProgressPercent) {
            this.clearVideoLoop();
            this.LoopVideo(this.loopTimer, isMobile, isTablet);
          }

          if (self.progress > 0.30) {
            document.querySelector('#introCopy').classList.toggle('opacity-0', false);
          } else {
            document.querySelector('#introCopy').classList.toggle('opacity-0', true);
          }

          if (self.progress > 0.95 || self.progress > 0.70 ) {
            if(!isTablet) {
              this.contentSection.style.top = `-${1080 - this.videoWrapper.offsetHeight}px`;
            } else if(!isMobile) {
              this.contentSection.style.top = `-${750 - this.videoWrapper.offsetHeight}px`;
            }
          }

        },

        onLeave: () => {

          ScrollTrigger.getAll().filter((scrollTrigger) => scrollTrigger.vars.id ? scrollTrigger.vars.id.startsWith('sliding-cards') : false).forEach(scrollTrigger => {
            scrollTrigger.refresh();
          })

          if(!isTablet) {
            this.contentSection.style.top = `-${1080 - this.videoWrapper.offsetHeight}px`;
          } else if(!isMobile) {
            this.contentSection.style.top = `-${750 - this.videoWrapper.offsetHeight}px`;
          }

        }

      },
    });

    //HeroStripes
    stripesTimeLine
    .fromTo(this.heroSideStripes, { opacity: 1 }, { opacity: 0 });

    //ContentWrapper
    contentWrapperTimeLine
    .fromTo('#scrollSection',
      { paddingTop: isMobile ? '100px' : isTablet ? '100px' : '200px' },
      { paddingTop: isMobile ? '100px' : '200px' })

    // Mask
    maskTimeLine
    .fromTo(this.heroStripeMask,
      { maskSize: isMobile ? '70% 70%' : isTablet ? '55% 55%' : isLaptop ? '50% 50%' : '70% 70%', maskPosition: '50% 50%' },
      { maskSize: '1100% 1100%', maskPosition: '50% 50%' });

    // Content
    contentTimeLine
    .fromTo(this.heroContentSection,
      { opacity: 1 },
      { opacity: isMobile ? 1 : 0, translateY: isMobile ? 'initial' : '-240px', height: isMobile ? 'inital' : isTablet ? '0px' : isLaptop ? '0px' : 'initlal', overflow: 'hidden' });

    videoTimeLine
    .fromTo('.video-container',
      { x: isMobile ? 'initial' : isDesktop ? '-38%' : isXlDesktop ? '-43%' : isLargeScreen ? '-40%' : '-46%', left: isMobile ? 'initial' : isTablet ? '10%' : isLaptop ? '-40%' : '-5%', width: isMobile ? '400px' : isTablet ? '1000px' : isXlDesktop ? '1920px' : '1920px' },
      { marginLeft: isLargeScreen ? calculatedMarginLeft : `-${currentPadding}`,
       x: isMobile ? 'initial' : isDesktop ? '0' : isLargeScreen ? '0' : '-12.5%',left: isMobile ? 'initial' : isDesktop ? '0' : '0', width: widthCalc});
  }

  clearPreviousStyles() {
    gsap.set(this.videoContainer, { clearProps: "all" });
    gsap.set(this.heroContentSection, { clearProps: "all" });
    gsap.set(this.heroStripeMask, { clearProps: "all" });
    gsap.set(this.contentSection, { clearProps: "all" });
    this.heroSideStripes.forEach(stripe => gsap.set(stripe, { clearProps: "all" }));
  }

  getVideoSource() {
    const width = window.innerWidth;
      if (width < this.minWidthForDTabletVideo) {
        return this.videoSources.mobile;
    } else if (width < this.minWidthForDesktopVideo) {
        return this.videoSources.tablet;
    } else {
        return this.videoSources.desktop;
    }
  }

  applyNewVideoSrc() {
    const currentSource = this.videoElement.getAttribute('src');
    const newSource = this.getVideoSource();
    if (currentSource !== newSource) {
        this.videoElement.setAttribute('src', newSource);
        this.videoElement.load();
    }
  }

  clearVideoLoop() {
    if (this.loop) {
      clearInterval(this.loop);
      this.loop = null;
    }
  }

  LoopVideo(timer) {
    this.clearVideoLoop();
    if (this.videoElement) {
      this.loop = window.setInterval(() => {
        this.videoElement.currentTime = 0;
      }, timer);
    }
  }

  calculateWidth(isXlDesktop) {
    return isXlDesktop ? '1920px' : `${window.innerWidth}px`;
  }

  handleResize = () => {
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    this.initScrollTriggers();
  }
}

export default AnimateFrontPage;
