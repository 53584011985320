import Modal from '@scripts/util/Modal.js';
import EmbedVideo from '@scripts/components/EmbedVideo.js'

class VideoModal extends Modal {

  constructor(toggle) {

    // Call Modal constructor
    super(toggle);

    this.player;

    // Set open and close callbacks
    this.onOpenCallback = this.playVideo;
    this.onCloseCallback = this.pauseVideo;

  }

  /**
   * Init and/or play an embed video
   */
  playVideo = async () => {

    // Init a new embed player when the content is replaced, or call play otherwise
    if(this.detail.state == 'replaced') {

      this.player = await new EmbedVideo(this.dataSlot.querySelector('.embed-video')).get();
      this.player.addEventListener('can-play', () => {
        this.player.play();
      });

    } else {

      this.player.play();

    }
  }

  /**
   * Pause the embed video
   */
  pauseVideo = () => {

    this.player.pause();

  }
}

export default VideoModal;
