export default {
  init() {
    const institutionsHero = document.querySelector('[data-for-students-hero]');
    const studentsHero = document.querySelector('[data-for-institutions-hero]');
    const institutionsBtn = document.querySelector('[data-for-students-btn]');
    const studentsBtn = document.querySelector('[data-for-institutions-btn]');
    const institutionsContent = document.querySelector('[data-for-students-content]');
    const studentsContent = document.querySelector('[data-for-institutions-content]');

    const buttons = [institutionsBtn ?? false, studentsBtn ?? false];

    if (buttons) {
      buttons.forEach(button => {
        button.addEventListener('click', () => displayContent(button));
      });
    }

    // Toggle Display content
    function displayContent(button) {
      const isForStudents = button === institutionsBtn;

      toggleContent(isForStudents);
      toggleButtonStyles(isForStudents);
    }

    function toggleContent(isForStudents) {
      if (institutionsContent) {
        institutionsContent.classList.toggle('hidden', !isForStudents);
      }

      if (institutionsHero) {
        institutionsHero.classList.toggle('hidden', !isForStudents);
      }

      if (studentsContent) {
        studentsContent.classList.toggle('hidden', isForStudents);
      }

      if (studentsHero) {
        studentsHero.classList.toggle('hidden', isForStudents);
      }

    }

    // Toggle button styles
    function toggleButtonStyles(isForStudents) {

      institutionsBtn.classList.toggle('btn-fill', isForStudents);
      institutionsBtn.classList.toggle('btn-outline', !isForStudents);

      studentsBtn.classList.toggle('btn-fill', !isForStudents);
      studentsBtn.classList.toggle('btn-outline', isForStudents);
    }
  }
}
