import { Navigation } from 'swiper/modules';
import Swiper from 'swiper';

class ConferenceSlider {

  constructor(node) {

    this.container = node;
    this.parent = node.closest('.swiper-parent');

    this.initSwiper();

    this.presentations = []

    const selectors = this.container.querySelectorAll('[data-presentation-selector]')

    selectors.forEach(selector => {

      this.initCards()
      this.changePresentation(selector)

      // Assign a function to the onchange event
      selector.onchange = () => {
        this.changePresentation(selector)
      };

    });
  }



  initSwiper() {

    this.swiper = new Swiper(this.container, {

      modules: [
        Navigation
      ],
      slidesPerView: 1.1,
      spaceBetween: 24,
      autoHeight: true,
      navigation: {
        nextEl: this.parent.querySelector('.swiper-button-next'),
        prevEl: this.parent.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
      }
    });
  }

  initCards() {

    const cards = this.container.querySelectorAll('[data-conference-card]')

    // Grab all titles and presentations
    cards.forEach(card => {

      //Check if the card has presentations
      if(card.querySelectorAll('.presentation')) {
        const selectorInCard = card.querySelector('[data-presentation-selector]')
        let currentSelector
        if(selectorInCard) {
          currentSelector = selectorInCard.id
        }

        const presentationsInCardValues = card.querySelectorAll('[data-presentation-value]')
        const presentationsInCardSpeakers = card.querySelectorAll('[data-speakers]')
        const cardId = card.id;
        const singlePresentationData = {cardId, currentSelector, presentationsInCardValues, presentationsInCardSpeakers}
        this.presentations.push(singlePresentationData)
      }

    });

  }

  changePresentation(selector) {

    let currentPresentation = selector.value;

    this.presentations.forEach(presentation => {

      if(presentation.cardId == selector.id) {

        const speakers = presentation.presentationsInCardSpeakers

        //Hide speakers of presentation does not match
        speakers.forEach(speakerList => speakerList.classList.toggle('hidden', speakerList.id != currentPresentation));

      }

    });

    this.swiper.updateAutoHeight();

  }
}


export default ConferenceSlider;
