// Breakpoints

const maxGrid = '1440px';

const screens = {
  'xs': '414px',
  'mobile': {'max': '639px'},
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'nav-drawer': {'max': '1319px'},
  'nav-bar': '1320px',
  'xl': '1280px',
  '2xl': '1440px',
  'lgvideo': '1441px',
  'cxl': {'max': '1919px', 'min': '1440px'},
  '3xl': '1920px',
  'mg': maxGrid,
}

// Container class
const container = {
  center: true,
  maxWidth: maxGrid,
  padding: {
    DEFAULT: '20px',
    'md': '32px',
    'lg': '48px',
    'xl': '64px',
  },
}

export default {
  screens: screens,
  container: container,
}
