import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import helpers from '@scripts/util/helpers';
gsap.registerPlugin(ScrollTrigger);

class SlidingCaseCards {

  constructor(container) {
    this.container = container;
    this.cards = Array.from(this.container.querySelectorAll("[data-sliding-card]"));
    this.namespace = `sliding-cards-${Math.random().toString(36)}`;
    this.isOnFrontPage = document.body.classList.contains('home');

    const resizeObserver = new ResizeObserver(() =>
      this.handleResize()
    )

    if(this.isOnFrontPage) {
      if(!helpers.isIOS()) {
        this.setupAnimations();
        resizeObserver.observe(document.body)
      }
    } else {
      this.setupAnimations();
      resizeObserver.observe(document.body)
    }

  }

  setupAnimations() {
    const isSmallerThanDesktop = window.innerWidth <= 1024;
    const masterTimeline = gsap.timeline();


      ScrollTrigger.getAll()
        .filter(trigger => trigger.vars.id && trigger.vars.id.startsWith(this.namespace))
        .forEach(trigger => trigger.kill());

      this.cards.forEach((card, index) => {
        const isLastCard = index === this.cards.length - 1;

        if (!isLastCard) {
          masterTimeline.add(gsap.timeline({
            scrollTrigger: {
              id: `${this.namespace}-card-${index}`,
              trigger: card,
              start: isSmallerThanDesktop ? 'top 0px' : 'top 200px',
              scrub: true,
              pin: true,
              pinSpacing: false,
            }
          })
          .fromTo(card,
            { top: isSmallerThanDesktop ? '0px' : '200px', scale: 1 },
            { top: isSmallerThanDesktop ? '0px' : '200px', scale: 0.7 }
          )
          .fromTo(card,
            { opacity: 1 },
            { opacity: 0 },
            "50%"  // This is for the animation timing
          ));
        }
      });

    if (!isSmallerThanDesktop) {
      const cardWrapper = this.container.querySelector('[data-cards-wrapper]');
      const content = this.container.querySelector('[data-sticky-content]');
      cardWrapper.style.paddingTop = `${content.offsetHeight}px`;

        const cardsWrapperHeight = cardWrapper.offsetHeight;
        const calculatedContentHeight = cardsWrapperHeight - (content.offsetHeight * 2);

        masterTimeline.add(gsap.timeline({
          scrollTrigger: {
            id: `${this.namespace}-content`,
            trigger: content,
            start: '-=300',
            end: `+=${calculatedContentHeight}`,
            pin: true,
            pinSpacing: true,
            ease: "Power2.in"
          }
        }));
    }
  }

  handleResize = () => {
    ScrollTrigger.getAll()
      .filter(trigger => trigger.vars.id && trigger.vars.id.startsWith(this.namespace))
      .forEach(trigger => trigger.kill());

    ScrollTrigger.refresh();

    this.setupAnimations();
  }


}

export default SlidingCaseCards;
