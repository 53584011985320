import {Autoplay, Navigation} from 'swiper/modules';
import Swiper from 'swiper';

class LogoSlider {

  constructor(container) {

    this.swiper = container;

    this.initSwiper();

  }


  /**
   * Initialize swiper instance
   */
  initSwiper() {

    new Swiper(this.swiper, {
      ...{
        modules: [
          Autoplay, Navigation
        ],
        slidesPerView: 3,
        spaceBetween: 24,
        loop: true,

        breakpoints: {
          500: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 48,
          },
        },
      },
      ...this.navigationOptions(),
    });
  }

  /**
   * Determine animation and navigation options based on hasNavigation data value
   * @returns object
   */
  navigationOptions() {

    if(this.swiper.dataset.hasNavigation) {

      // Options for swiper with navigation
      return {
        grabCursor: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: this.swiper.querySelector('.swiper-button-next'),
          prevEl: this.swiper.querySelector('.swiper-button-prev'),
        },
      }

    } else {

      // Options for swiper without navigation
      return {
        speed: 3700,
        waitForTransition: false,
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
        allowTouchMove: false,
      }

    }
  }

}

export default LogoSlider;
