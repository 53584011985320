import grid from '../../../_tailwind/config/grid'

class Breakpoints {

  // Breakpoints object.
  static breakpoints = this.breakpointsFromScreens();

  constructor(queries) {

    this.currentSize = 'default';
    this.queries = queries;
    this.breakpoints = Breakpoints.breakpoints;

    // The queries object must have a default property
    if(!queries.hasOwnProperty('default')) {

      throw 'Queries object given to watch method must have a property called \'default\'';

    }

    this.watch();

  }

  /**
   * Get the current size, run it's callback and do that again when the window resizes
   */
  watch() {

    // Get the current size and run the callback once
    this.currentSize = this.getSize();

    this.runCallback(this.queries);

    // On resize, check if the size has changed, if so, run the matching callback
    window.addEventListener('resize', () => {

      let newSize = this.getSize();

      if(newSize != this.currentSize) {

        this.currentSize = newSize;
        this.runCallback(this.queries);

      }

    });

  }

  /**
   * Get the current size, if no breakpoint matches, the default size will be returned
   * @return {string}
   */
  getSize() {

    let result = 'default';

    // Loop through all given queries
    for(const size in this.queries) {

      // If a query matches, make that size the result
      if(window.matchMedia(this.breakpoints[size]).matches) {

        result = size;

      }
    }

    return result;
  }

  /**
   * Run the callback from the queries object, throw error if the callback is not a function
   */
  runCallback() {

    // Run the callback of the activeSize or throw an error of the value is not a function
    if(typeof this.queries[this.currentSize] === 'function') {

      this.queries[this.currentSize].call();

    } else {

      throw `The property ${this.currentSize} must be of type function`;

    }
  }

  /**
   * Static method to check if a breakpoint matches
   * @param {string} size
   * @returns boolean
   */
  static isBreakpoint(size) {

    return window.matchMedia(this.breakpoints[size]).matches;

  }

  /**
   * Create a breakpoints object from tailwind screens config
   * @returns object
   */
  static breakpointsFromScreens() {

    let breakpoints = {};

    for (const [name, breakpoint] of Object.entries(grid.screens)) {

      let widths = [];

      // Create min and max widths
      if(typeof breakpoint == 'object') {

        for (const [key, width] of Object.entries(breakpoint)) {

          widths.push(`(${key}-width: ${width})`);

        }
      }

      // Only a width, so using min-width
      if(typeof breakpoint == 'string') {
        widths.push(`(min-width: ${breakpoint})`);
      }

      // Concatenate all width specifications in a media query string and add it to the breakpoints object
      breakpoints[name] = `screen and ${widths.join(' and ')}`;

    }

    return breakpoints;

  }
}

export default Breakpoints;
