class Accordion {

  constructor(node) {

    this.accordion = node;

    this.initPanels();

  }

  /**
   * Start listeners for all panel buttons
   */
  initPanels() {

    this.accordion.querySelectorAll('[aria-controls]').forEach((toggleButton) => {



      // Find the panel that the button controls
      const panel = this.findPanel(toggleButton);

      // Listen to clicks on the button
      toggleButton.addEventListener('click', () => this.handlePanelToggle(toggleButton, panel));

      // Call setPanelHeight for the panel
      this.setPanelHeight(panel);

    });

    // Set a window resize event
    window.addEventListener('resize', () => {

      // Call resizePanelHeight for all expanded panels
      this.accordion.querySelectorAll('[aria-controls][aria-expanded=true]').forEach((toggleButton) => {

        this.resizePanelHeight(this.findPanel(toggleButton));

      });
    });

  }

  /**
   * Find panel associated with toggle button
   * @param {HTMLElement} toggleButton
   * @returns HTMLElement
   */
  findPanel(toggleButton) {

    return this.accordion.querySelector('#'+toggleButton.getAttribute('aria-controls'));

  }

  /**
   * Handle click on the toggle button
   * @param  {HTMLElement} toggleButton
   * @param  {HTMLElement} panel
   */
  handlePanelToggle(toggleButton, panel) {

    let shouldExpand = toggleButton.getAttribute('aria-expanded') == 'true' ? false : true;

    toggleButton.setAttribute('aria-expanded', shouldExpand);
    panel.setAttribute('aria-hidden', !shouldExpand);

    this.setPanelHeight(panel);

  }


  /**
   * Set a css variable for the panel height
   * @param  {HTMLElement} panel
   */
  setPanelHeight(panel) {

      let panelHeight = panel.scrollHeight;

      panel.setAttribute('style', `--panel-height:${panelHeight}px`);

  }

  /**
   * Remove panel height css variable before calculating new size
   * @param {HTMLElement} panel
   */
  resizePanelHeight(panel) {

    panel.removeAttribute('style');

    this.setPanelHeight(panel);

  }

}

export default Accordion;
